
<template>
  <div class="container">
    <h2>PassWard Privacy Policy</h2>
    <br/>
    <h3>ABOUT</h3>
    <p>
      Abdulmohsen Alkhamees built the PassWard app as a Free app. This PassWard
      is provided by Abdulmohsen Alkhamees at no cost and is intended for use as
      is.This page is used to inform visitors regarding my policies with the
      collection, use, and disclosure of Personal Information if anyone decided
      to use my app. If you choose to use my App, then you agree to the
      collection and use of information in relation to this policy.
    </p>

    <br />
    <h3>Data Collection</h3>
    <p>
      The Personal Information that is collected/stored via this app are only
      stored locally within the app. I will not use or share your information
      with anyone except as described in this Privacy Policy. Information
      Collection and Use, Currently the app does not collect or share the data
      externally or over the internet, in otherwords application doesn't employ
      internet services or have device permissions to send data over the
      internet, however this may change in the future with new introduced
      features and accordingly the Privacy Policy will be updated.
    </p>

    <br />
    <h3>Security</h3>
    <p>
      I value your trust in providing us your Personal Information, thus we are
      striving to use commercially acceptable means of protecting it. But
      remember that no method or mean is 100% secure and reliable, and I cannot
      guarantee its absolute security.
    </p>

    <br />
    <h3>Links to Other Links</h3>
    <p>
      This Service may contain links to other links/services/apps for instance
      when using PassWard to share credentials with another app. If you click on
      a third-party links/services/apps, you will be directed to that site. Note
      that these external sites are not operated by me. Therefore, I strongly
      advise you to review the Privacy Policy of these websites. I have no
      control over and assume no responsibility for the content, privacy
      policies, or practices of any third-party sites or services.
    </p>
    <br />
    <h3>Restrictions</h3>
    <p>
      Children 2021s Privacy PassWard do not address anyone under the age of 13.
      I do not knowingly collect personally identifiable information from
      children under 13 neither older.
    </p>
    <br />
    <h3>Changes to this Privacy Policy</h3>
    <p>
      I may update our Privacy Policy from time to time. Thus, you are advised
      to review this page periodically for any changes. I will notify you of any
      changes by posting the new Privacy Policy on this page. These changes are
      effective immediately after they are posted on this page. Contact Us.
    </p>
    <br />
    <p>
      If you have any questions or suggestions about my Privacy Policy, do not
      hesitate to contact me at abdulmohsen@alkhamees.co
    </p>
  </div>
</template>

<style scoped>
h3 {
  text-align: start;
}

p {
  word-spacing: 0.5px;
  text-align: start;
  font-size: 18px;
  color: #e4e4e4;
}

.container {
  margin: 20px;
}
</style>